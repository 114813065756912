/* colours */
$bg_primary: #ffda00; /* yellow */
$primary: #f2da1b;/* slightly darker yellow */
$secondary: #c04427; /* red */

$tint: #f2f2f2; /*offwhite */
$bg_midg: #998675; /* mid grey */
$bg_darkg: #736357; /* darker grey */

/* common */
$footer-bg: url('../assets/footer-bg.jpg');
$font: "paralucent-condensed",sans-serif;

/* functions */
@mixin gradientBg($deg: 90deg, $opacity: 0.7){
    background: $secondary;
    background: -webkit-linear-gradient($deg, $secondary, $bg_primary);
    background: linear-gradient($deg, $secondary, $bg_primary);
    opacity: $opacity;
}

@mixin before_circle($colour: $primary){ /* all dots the same */
    display: inline-block;
    vertical-align: center;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid $colour;
    background-color: transparent;
    margin-right: 10px;
    transition: all 0.5s ease;
}

@mixin darken($opacity: 0.3){
    content:"";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #000;
    mix-blend-mode: multiply;
    opacity: $opacity;
}